import React from "react";
import PitchesOverview from "../components/PitchesOverview";
import FooterCTA from "../components/FooterCTA";

const Archive = () => {
  return (
    <div>
      <div className="content" style={{ marginTop: 90 }}>
        <div
          className="text-page-fixed-row"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="page-subject ">
            <div className="golden-divider"></div>
            <span className="extra90degreespage bold">
              Pitches archief &nbsp;
            </span>
            <span className="extra90degreespage">Proud Nerds</span>
          </div>
          <div
            className="container dynamic-padding"
            style={{ paddingBottom: 290 }}
          >
            <span className="striped-background "></span>
            <div
              id="upper-content"
              className="upper-content px-slide-in px-slide-delay-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
            >
              <div className="breadcrumbs">
                <span className="white">&nbsp;/&nbsp;</span>
                <span className="extra-breadcrumbs white">PITCHES</span>
                <span className="white">&nbsp;/&nbsp;</span>

                <span className="extra-breadcrumbs white">Off the pitch</span>
              </div>
              <div className="text-content ">
                <div className="golden-divider"></div>
                <h1 className="px-slide-in px-slide-delay-1">Off the pitch</h1>
                <h4 className="px-slide-in px-slide-delay-2 ">
                  Hier staan de pitches van de vorige innovatiemiddagen. De bijbehorende presentaties worden zo snel mogelijk hieraan toegevoegd. Sommige pitches zijn afgerond. Wij zijn op dit moment aan het kijken hoe pitches, die doorontwikkeld kunnen worden als een project opgepakt kunnen worden. Zodra daar meer over bekend is, zal je daarover geïnformeerd worden via de nieuwsbrief. 
                </h4>
              </div>
            </div>
            <div
              className="dynamic-placeholder px-slide-in px-slide-delay-3 col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2"
              style={{ marginTop: -290 }}
            >
              <img
                alt="header"
                src="/images/PN-inno-header.png"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images/PN-inno-header.png";
                }}
                style={{
                  paddingTop: 50,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                className="image dynamic-movable"
                width="740"
                height="440"
              />
            </div>
          </div>
        </div>
      </div>
      <PitchesOverview topMargin={100} isArchive />
      <FooterCTA />
    </div>
  );
};

export default Archive;
