import React from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <footer
      className="px-footer"
      style={{
        height: 100,
      }}
    >
      <Link href="/">
        <img
          className="header-logo"
          alt="header logo"
          src="/images/proud-nerds-logo-rgb-wit.svg"
        />
      </Link>
      <div className="container">
        <div className="row copyright-div">
          <p className="col-12">© Proud Nerds</p>
        </div>
        <div className="row footer-desktop-view">
          <span className="footer-copyright">© Proud Nerds</span>
        </div>
      </div>
      {pathname !== "/pitches/succes" && (
        <span className="footer-shaded-illustration bg-white"></span>
      )}
    </footer>
  );
};

export default Footer;
