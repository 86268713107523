import React from "react";

const CupSection = () => {
  return (
    <div
      className="px-three-column-row  dynamic-margin container"
      style={{ marginBottom: 118 }}
    >
      <div
        className="dynamic-placeholder"
        style={{ height: "355.312px", margin: "100px 0" }}
      >
        <a
          href="/cases/"
          target=""
          title="Cases"
          className="card-placeholder dynamic-movable row no-gutters"
          style={{ transform: "translateY(0px)" }}
        >
          <div className="image-placeholder col-12 col-lg-11">
            <div className="striped-background"></div>
            <div className="image">
              <img src="/images/PN-inno-cup.png" alt="PN cup" />
            </div>
          </div>
          <nav
            className="px-card col-12 col-md-8 offset-md-3 col-lg-5 offset-lg-6"
            style={{ backgroundColor: "#f8f8f8", padding: 50 }}
          >
            <h3 className="black">Eeuwige roem</h3>
            <p
              className="read black"
              style={{ lineHeight: "23px", letterSpacing: 1.62 }}
            >
              Naast eeuwige roem, applaus en grenzeloos respect van je collega’s
              maak je ook kans op de Proud Pitch of the Year award. Op alle
              pitches wordt aan het eind van het jaar gestemd welke pitch het
              meest innovatief is geweest. Je naam prijkt een jaar lang op de
              wisselbeker, die een mooi plekje krijgt binnen de Splendor
              fabriek.
            </p>
          </nav>
        </a>
      </div>
    </div>
  );
};

export default CupSection;
