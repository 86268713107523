import "./css/Website.scss";
import Routes from "./Routes";
function App() {
  console.log(
    `%c ________________________________________
< mooooooooooooooooooooooooooooooooooooo >
 ----------------------------------------
        \\   ^__^
         \\  (oo)\\_______
            (__)\\       )\\/\\
                ||----w |
                ||     ||`,
    "font-family:monospace"
  );
  console.log(
    "Hi hackerman/hackerwoman! Deze codebase is op zijn zachtst gezegd niet de allermooiste, kan jij dit nou een stuk beter en wil je mee werken aan een V2 van de innovatiemiddag. Stuur even een bericht naar Tim R en dan kunnen we er over sparren!"
  );

  return (
    <div className="wrapper">
      <Routes />
    </div>
  );
}

export default App;
