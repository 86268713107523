import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FooterCTA from "../components/FooterCTA";

const Pitch = (props) => {
  const { pathname } = useLocation();
  const [pitch, setPitch] = useState([]);

  useEffect(() => {
    const id = pathname.replace("/pitches/", "");

    return fetch(
      `https://innovatiemiddag.proudnerds.com/.netlify/functions/server/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setPitch(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [pathname]);

  return pitch.length && pitch[0] ? (
    <div className="content" style={{ marginTop: 45 }}>
      <Link to="/#pitches">
        <div
          className="go-back"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <img src="/images/arrow-short.svg" alt="go back" />
          <p className="text-black" style={{ fontSize: 8, margin: "3px 0 0" }}>
            HOME
          </p>
        </div>
      </Link>
      <div
        className="text-page-fixed-row"
        style={{ backgroundColor: "#1a1919" }}
      >
        <div className="page-subject ">
          <div className="golden-divider"></div>
          <span className="extra90degreespage white bold">Pitch &nbsp;</span>
          <span className="extra90degreespage white">Proud Nerds</span>
        </div>
        <div
          className="container dynamic-padding"
          style={{ paddingBottom: 290 }}
        >
          <span className="striped-background  bg-black"></span>
          <div
            id="upper-content"
            className="upper-content px-slide-in px-slide-delay-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
          >
            <div className="breadcrumbs">
              <a className="extra-breadcrumbs active white" href="/">
                HOME
              </a>
              <span className="white">&nbsp;/&nbsp;</span>
              <span className="extra-breadcrumbs white">PITCHES</span>
              <span className="white">&nbsp;/&nbsp;</span>

              <span className="extra-breadcrumbs white">
                {pitch[0].ideaName}
              </span>
            </div>
            <div className="text-content ">
              <div className="golden-divider"></div>
              <h1 className="white px-slide-in px-slide-delay-1">
                {pitch[0].ideaName}
              </h1>
              <h4 className="white px-slide-in px-slide-delay-2 ">
                {pitch[0].idea}
              </h4>
            </div>
          </div>
          <div
            className="dynamic-placeholder px-slide-in px-slide-delay-3 col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2"
            style={{ marginTop: -290 }}
          >
            <img
              alt="header"
              src={
                pitch[0].imageLink
                  ? pitch[0].imageLink
                  : "/images/PN-inno-header.png"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images/PN-inno-header.png";
              }}
              style={{
                paddingTop: 50,
                objectFit: "cover",
                objectPosition: "center",
              }}
              className="image dynamic-movable"
              width="740"
              height="440"
            />
          </div>
        </div>
      </div>

      <div className="nested-content" style={{ backgroundColor: "#ffffff" }}>
        <div style={{ backgroundColor: "#ffffff" }}>
          <div className="container">
            <section className="title-text-image-row row">
              <div className="text-content col-12 col-md-9 col-lg-7">
                <div className="sub-title">
                  <div className="golden-divider"></div>
                  <span className="extraintro titlegold"></span>
                </div>
                <div className="rich-text read black">
                  <p>{pitch[0].ideaLong}</p>
                </div>
              </div>
              <div className="text-content col-12 col-md-3 offset-md-1 col-lg-3">
                <div className="button-placeholder teams-link">
                  <a
                    href={pitch[0].teamsLink}
                    alt="Go to teams link"
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <div className="icon">
                      {" "}
                      <img
                        src="/images/pn-innovation-icons_ms-teams.svg"
                        alt="Go to pitch microsoft teams link "
                      />
                    </div>

                    <p className="black bold">
                      SLUIT JE AAN
                      <img
                        src="/images/arrow-short.svg"
                        alt="Go to the teams link"
                      />
                    </p>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div style={{ backgroundColor: "#ffffff" }}>
          <div
            className="container  text-block-rich-container"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="row col-12 col-md-10 col-lg-10 ">
              <div
                className="rich-text read black"
                style={{ backgroundColor: "#ffffff", color: "black" }}
              >
                {pitch?.[0]?.externalLink?.length ? (
                  <h3>Inspirerend leesvoer</h3>
                ) : null}

                {pitch?.[0]?.externalLink?.map((link, index) => (
                  <a
                    href={link["link" + index]}
                    alt={link["link" + index]}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    {link["link" + index]}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Link className="case-detail-link" to="/">
          <div
            className="image-card-row-container container dynamic-padding"
            style={{
              paddingBottom: "60px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <section className="image-card-row">
              <div className="image">
                <picture style={{ width: "100%", overflow: "hidden" }}>
                  <img src="/images/PN-inno-team.png" alt="team" />
                </picture>
                <div className="striped-decoration"></div>
              </div>
              <div
                className="text-content dynamic-movable"
                style={{ zIndex: 1 }}
              >
                <h3 className="white">Toch niet wat je zocht?</h3>
                <div className="rich-text read white">
                  <p>Bekijk de overige pitches en wordt crew member.</p>
                </div>
                <span className="link-info">
                  <p className="text-holder white">
                    <b>bekijk pitches</b>
                    <img
                      src="/images/arrow-short-white.svg"
                      alt="Go to pitch"
                    />
                  </p>
                </span>
              </div>
            </section>
          </div>
        </Link>

        <FooterCTA />
      </div>
    </div>
  ) : (
    <p
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      De pitch wordt geladen...
    </p>
  );
};

export default Pitch;
