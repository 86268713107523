import React from "react";

const HomepageIntro = () => {
  return (
    <div>
      <div className="page-subject">
        <div className="golden-divider trimmed"></div>
        <span className="extra90degreespage white bold">
          Innovation pitches&nbsp;
        </span>
        <span className="extra90degreespage white">Proud Nerds</span>
      </div>
      <div
        className="px-homepage-header pn-video-header"
        style={{
          background:
            "#1a1919 linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
        }}
      >
        <section
          className="container"
          style={{ backgroundColor: "transparent" }}
        >
          <div
            className="row no-gutters justify-content-md-end"
            style={{ position: "relative" }}
          >
            <div className="text-area">
              <h1 className="white px-slide-in px-slide-delay-0">
                we are <span className="blinking-underscore white">_</span>
              </h1>
              <h1 className="white px-slide-in px-slide-delay-1">innovators</h1>
            </div>
            <div className="video-container">
              <div className="video-wrapper playing">
                <img
                  src="/images/PN-inno-header.png"
                  width="1050"
                  height="650"
                  alt="test"
                />
              </div>
              <div className="arced-background"></div>
            </div>
          </div>
        </section>
      </div>

      <div className="nested-content" style={{ backgroundColor: "#ffffff" }}>
        <section className="three-row-outer six-row-outer">
          <div
            className="px-three-column-row six-column-row dynamic-margin container"
            style={{ marginBottom: "0px" }}
          >
            <div className="dynamic-placeholder" style={{ height: "0px" }}>
              <a
                href="/wie-zijn-wij/"
                className="card-placeholder dynamic-movable row no-gutters"
                style={{ transform: "translateY(0px)" }}
              >
                <nav className="px-card black col-12 col-md-9 col-lg-7 col-xl-5">
                  <p className="read white">
                    Elk kwartaal organiseert Proud Nerds een innovatiemiddag;
                    een middag waar je los mag gaan op nieuwe technieken, nieuwe
                    trends in je vakgebied of dat ene coole idee waarvan jij
                    weet dat onze klanten nog niet beseffen hoe hard ze die
                    feature nodig hebben.
                  </p>
                </nav>
              </a>
            </div>
            <div className="columns-placeholder row">
              <div className="col-12">
                <div className="row"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomepageIntro;
