import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

const PitchAddForm = () => {
  const [links, setLinks] = useState([{ link0: "" }]);
  const [formData, setFormData] = useState({
    name: "",
    ideaName: "",
    idea: "",
    ideaLong: "",
    imageLink: "",
    externalLink: links,
    teamsLink: "",
    timestamp: new Date().toISOString(),
  });
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !formData.name ||
      !formData.idea ||
      !formData.ideaName ||
      !formData.teamsLink
    ) {
      return setError(
        "Er zijn nog velden niet ingevuld, vul ze in en probeer het nogmaals."
      );
    }
    formData.name !== "" &&
      formData.idea !== "" &&
      fetch(
        "https://innovatiemiddag.proudnerds.com/.netlify/functions/server/pitches",
        {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )
        .then((response) => {
          setDone(true);
          setFormData({
            name: "",
            ideaName: "",
            idea: "",
            ideaLong: "",
            imageLink: "",
            externalLink: [{ link0: "" }],
            teamsLink: "",
            timestamp: new Date().toISOString(),
          });
          setError("");
          setLoading(false);

          history.push("/pitches/succes");
          return response.json();
        })
        .then((data) => {
          setDone(true);
          setLoading(false);
          return;
        })
        .catch((error) => {
          console.error("Error:", error);
          setError(error);
          setLoading(false);
        });
  };

  const handleChange = (e) => {
    const value = e?.target?.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleLinkChange = (e, index) => {
    const value = e?.target?.value;
    links[index] = { [e.target.name]: value };
  };

  return (
    <div className="container">
      <div
        className="upper-content px-slide-in px-slide-delay-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
        zIndex={{ zIndex: 10000 }}
      >
        <section class="module-formrow layout-floating-form row-bg-black">
          <form
            action="/pitches/add#"
            autocomplete="off"
            class="sml"
            enctype="multipart/form-data"
            method="post"
            novalidate="novalidate"
            onSubmit={handleSubmit}
            _lpchecked="1"
            id="form"
          >
            <div class="ffl-wrapper">
              <h3>Jouw gegevens</h3>
              <p className="text-black">
                Je wilt natuurlijk even shinen met je briljante idee. Laat aan
                je collega’s weten wie je bent en wat je functie is.
              </p>
              <label style={{ marginBottom: 20 }} class="ffl-label" for="Name">
                Naam
              </label>
              <input
                onChange={handleChange}
                ariaRequired="True"
                dataVal="true"
                dataValRequired="Het invullen van een naam is verplicht."
                placeholder="Naam en functie"
                id="name"
                name="name"
                type="text"
                value={formData.name}
                style={{
                  backgroundColor: "#ececeb",
                  border: 0,
                  backgroundImage:
                    "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==')",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "scroll",
                  backgroundSize: "16px 18px",
                  backgroundPosition: "98% 50%",
                  cursor: "auto",
                }}
              />
              <span
                class="field-validation-valid"
                data-valmsg-for="Name"
                data-valmsg-replace="true"
                role="alert"
              ></span>
            </div>
            <h3>Jouw idee</h3>
            <p className="text-black">
              Nerds houden van lezen, maar ook van efficientie. Geef dus een
              korte omschrijving van je idee (max 370 karakters). Wil je meer
              vertellen over jouw idee? Geen probleem, gebruik daarvoor het
              uitgebreide omschrijvingsveld.
            </p>
            <div className="ffl-wrapper">
              <label
                style={{ marginBottom: 20 }}
                class="ffl-label"
                for="ideaName"
              >
                Naam van je idee
              </label>
              <input
                onChange={handleChange}
                aria-required="True"
                data-val="true"
                id="ideaName"
                name="ideaName"
                type="text"
                placeholder="Naam van je idee"
                value={formData.ideaName}
                style={{
                  backgroundColor: "#ececeb",
                  border: 0,
                  backgroundImage:
                    "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==')",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "scroll",
                  backgroundSize: "16px 18px",
                  backgroundPosition: "98% 50%",
                  cursor: "auto",
                }}
              />
            </div>
            <div class="ffl-wrapper">
              <label style={{ marginBottom: 20 }} class="ffl-label" for="idea">
                Pitch je idee
              </label>
              <textarea
                aria-required="True"
                required
                cols="20"
                data-val="true"
                data-val-required="Het invullen van een bericht is verplicht."
                id="idea"
                name="idea"
                onChange={handleChange}
                rows="2"
                maxLength="370"
                placeholder="Maximaal 370 karakters"
                style={{
                  backgroundColor: "#ececeb",
                  border: 0,
                  resize: "none",
                }}
              ></textarea>
              <span
                class="field-validation-valid"
                data-valmsg-for="idea"
                data-valmsg-replace="true"
                role="alert"
              ></span>
            </div>

            <div class="ffl-wrapper">
              <label
                style={{ marginBottom: 20 }}
                class="ffl-label"
                for="ideaLong"
              >
                Uitgebreide omschrijving (optioneel)
              </label>
              <textarea
                onChange={handleChange}
                aria-required="True"
                cols="50"
                data-val="true"
                id="ideaLong"
                name="ideaLong"
                rows="15"
                placeholder="Uitgebreide omschrijving van je idee"
                style={{ backgroundColor: "#ececeb", border: 0 }}
              ></textarea>
            </div>

            <p className="text-black">
              Wil je opvallen tussen al die andere ideeën? Plaats een link van
              je afbeelding! (minimale formaat 840x480 pixels). Hoeft niet, maar
              dan krijg je van ons een saaie, grijze standaardafbeelding. Wat
              jij wil!
            </p>

            <p className="bold">TIP</p>
            <p>
              Zoek eens bij{" "}
              <a
                href="https://images.google.com"
                target="_blank"
                className="text-black"
                rel="noreferrer nofollow"
              >
                Google images
              </a>
              ,{" "}
              <a
                href="https://unsplash.com/"
                target="_blank"
                className="text-black"
                rel="noreferrer nofollow"
              >
                Unsplash
              </a>{" "}
              of{" "}
              <a
                href="https://www.pexels.com/nl-nl/"
                target="_blank"
                className="text-black row-bg-gray"
                rel="noreferrer nofollow"
              >
                Pexels
              </a>{" "}
              naar afbeeldingen die passen bij jouw pitch! Om de afbeeldingen
              toe te voegen, gebruik je rechtermuisknop om de afbeelding url te
              kopieren en plak deze in het onderstaande veld.
            </p>
            <div className="ffl-wrapper" style={{ margin: "30px 0" }}>
              <label style={{ marginBottom: 20 }} class="ffl-label" for="Name">
                Afbeelding
              </label>
              <input
                onChange={handleChange}
                aria-required="True"
                data-val="true"
                id="imageLink"
                name="imageLink"
                type="text"
                placeholder="Link naar afbeelding"
                value={formData.imageLink}
                style={{
                  backgroundColor: "#ececeb",
                  border: 0,
                  backgroundImage:
                    "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==')",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "scroll",
                  backgroundSize: "16px 18px",
                  backgroundPosition: "98% 50%",
                  cursor: "auto",
                }}
              />
            </div>
            <div
              className="row"
              style={{
                margin: "50px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className=" col-md-2 ">
                <img
                  src="/images/afbeelding_downloaden.png"
                  alt="Download images"
                  style={{ marginLeft: -25 }}
                  width="226"
                />
              </div>
              <div className=" col-md-7 offset-md-3">
                <p>
                  Om de link naar de afbeelding te achterhalen, klik je op de
                  afbeelding met je rechtermuisknop. In het menu kies je
                  vervolgens voor:
                  <ul style={{ marginTop: 15 }}>
                    <li>Google Chrome: ”Copy Image URL”.</li>
                    <li>Safari: ”Copy Image Address”.</li>
                    <li>
                      Internet Explorer (serieus?): ”Properties.” Vervolgens
                      kopieer je het adres bij “Address (URL)”.
                    </li>
                    <li>Mozilla Firefox:” Copy Image Location.”</li>
                  </ul>
                </p>
              </div>
            </div>
            <p>
              Mischien heb je online al wat interessante links gevonden, die je
              collega’s kunnen overtuigen om crew member te worden. Voeg hier de
              links toe.
            </p>

            <div className="ffl-wrapper" style={{ margin: "30px 0" }}>
              <label style={{ marginBottom: 20 }} class="ffl-label" for="Name">
                Externe link
              </label>
              {links.map((item, index) => (
                <input
                  onChange={(e) => handleLinkChange(e, index)}
                  aria-required="True"
                  data-val="true"
                  id={"link" + index}
                  name={"link" + index}
                  type="text"
                  placeholder="Voeg externe link toe"
                  value={item[0]}
                  style={{
                    backgroundColor: "#ececeb",
                    marginTop: 10,
                    border: 0,
                    backgroundImage:
                      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==')",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "scroll",
                    backgroundSize: "16px 18px",
                    backgroundPosition: "98% 50%",
                    cursor: "auto",
                  }}
                />
              ))}
              <div
                className="button-placeholder"
                style={{
                  margin: "25px auto 0",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <a
                  href="/pitches/add#"
                  title="Contact"
                  className="btn-size-l btn-trans row-bg-gray btn-icon-right"
                  onClick={(e) => {
                    e.preventDefault();
                    setLinks([...links, { ["link" + links.length + 1]: "" }]);
                  }}
                >
                  Nog een link toevoegen
                </a>
              </div>
            </div>
            <p className="text-black">
              COVID-bacillen krijgen geen ruimte op de innovatiemiddag. Maak een
              Microsoft team aan, waar collega’s zich aan kunnen melden als crew
              member. Deel de link van je Microsoft team*.
            </p>
            <div class="ffl-wrapper" style={{ margin: "30px 0" }}>
              <label
                style={{ marginBottom: 20 }}
                class="ffl-label"
                for="teamsLink"
              >
                Microsoft team link
              </label>
              <input
                onChange={handleChange}
                aria-required="True"
                data-val="true"
                id="teamsLink"
                name="teamsLink"
                type="text"
                placeholder="Voeg externe link toe"
                value={formData.teamsLink}
                style={{
                  backgroundColor: "#ececeb",
                  border: 0,

                  backgroundImage:
                    "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==')",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "scroll",
                  backgroundSize: "16px 18px",
                  backgroundPosition: "98% 50%",
                  cursor: "auto",
                }}
              />
            </div>

            <h4 className="bold text-black">Team aanmaken</h4>

            <div
              className="row"
              style={{
                margin: "50px 0 75px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="col-md-5">
                <p>
                  Om je Team aan te maken, open je de Microsoft team app. Klik
                  vervolgens in het rechtermenu op “Teams”. Je krijgt nu een
                  overzicht van al je teams. Onderaan dit overzicht staat een
                  button “Join or create a team.” Wanneer je hierop klikt, krijg
                  je de mogelijkheid om een team aan te maken. Doorloop de
                  stappen. Zorg ervoor dat je kiest voor een “Public team”.
                </p>
              </div>
              <div className="col-md-3">
                <img src="/images/teams-aanmaken.png" alt="teams aanmaken" />
              </div>
            </div>

            <div
              className="row"
              style={{
                margin: "25px 0 50px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className=" col-md-3 ">
                <img src="/images/teams.png" alt="teams" />
              </div>
              <div className=" col-md-6 offset-md-3">
                <p>
                  Wanneer je een Microsoft team hebt aangemaakt, ga je naar de
                  general room, en klik je op de 3 bolletjes helemaal rechts
                  bovenaan. In het uitklapmenu klik je vervolgens op “Get link
                  to channel”.
                </p>
              </div>
            </div>

            <div className="submit-section" style={{ padding: "100px 0 30px" }}>
              <p>
                That’s it! Ga voor eeuwige roem, sla de gegevens op en deel je
                pitch met je collega’s.
              </p>
              <div
                className="button-placeholder"
                style={{
                  marginTop: 45,
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <a
                  href="/pitches/add#"
                  onClick={handleSubmit}
                  class="contact-form btn-size-l btn-trans row-bg-white btn-icon-right"
                >
                  <input
                    class="sml"
                    type="submit"
                    title="Verstuur"
                    value="Verstuur"
                    style={{ display: "none" }}
                  />
                  <span class="icon-right" data-icon=""></span>
                  {loading && !error ? "Pitch versturen..." : "Pitch indienen"}
                </a>
              </div>
              {done ? (
                <p
                  style={{ marginTop: 25, width: "100%", textAlign: "center" }}
                >
                  Dankjewel, je pitch is verstuurd!{" "}
                  <Link to="/#pitches" className="text-black">
                    Bekijk alle pitches{" "}
                    <img
                      src="/images/arrow-short.svg"
                      alt="Go to pitches overview"
                    />
                  </Link>
                </p>
              ) : null}
              {!done && error ? (
                <p
                  style={{ marginTop: 25, width: "100%", textAlign: "center" }}
                >
                  {error.message || error}
                </p>
              ) : null}
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default PitchAddForm;
