import React, { useState } from "react";

const Login = ({ setAuthenticated }) => {
  const [ww, setWw] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setWw(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ww === process.env.REACT_APP_LOGIN_WW) {
      setAuthenticated(true);
    } else {
      setError("Het wachtwoord is onjuist, probeer het nogmaal");
    }
  };
  return (
    <div className="content" style={{ marginTop: 45 }}>
      <div className="text-page-fixed-row">
        <div className="page-subject ">
          <div className="golden-divider"></div>
          <span className="extra90degreespage black bold">Login &nbsp;</span>
          <span className="extra90degreespage black">Proud Nerds</span>
        </div>
        <div
          className="container dynamic-padding"
          style={{
            paddingBottom: "150px",
            marginTop: 140,
          }}
        >
          <span className="striped-background  bg-white"></span>
          <div className="upper-content px-slide-in px-slide-delay-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="text-content " style={{ paddingTop: 50 }}>
              <div className="golden-divider"></div>
              <h1 className="black px-slide-in px-slide-delay-1">Login</h1>
              <section className="module-formrow layout-floating-form row-bg-white">
                <form
                  action=""
                  onSubmit={handleSubmit}
                  autocomplete="off"
                  className="sml"
                  enctype="multipart/form-data"
                  method="post"
                  novalidate="novalidate"
                  _lpchecked="1"
                >
                  <div className="ffl-wrapper">
                    <label
                      style={{ marginBottom: 20 }}
                      className="ffl-label"
                      for="ww"
                    >
                      Wachtwoord
                    </label>
                    <input
                      aria-required="True"
                      data-val="true"
                      id="ww"
                      name="ww"
                      type="password"
                      placeholder="Wachtwoord"
                      value={ww}
                      required
                      onChange={handleChange}
                      style={{
                        backgroundColor: "#ececeb",
                        border: 0,
                        backgroundImage:
                          "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==')",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "16px 18px",
                        backgroundPosition: "98% 50%",
                        cursor: "auto",
                      }}
                    />
                  </div>
                  {error ? <p>{error}</p> : null}
                  <div
                    className="button-placeholder"
                    style={{
                      marginTop: 45,
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <button
                      type="submit"
                      onclick={handleSubmit}
                      className="contact-form btn-size-l btn-trans row-bg-white btn-icon-right"
                    >
                      <input
                        style={{ display: "none" }}
                        className="sml"
                        type="submit"
                        title="Verstuur"
                        value="Verstuur"
                      />
                      <span
                        className="icon-right"
                        style={{ marginTop: 13 }}
                        data-icon=""
                      ></span>
                      Inloggen
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
