import React from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const InnovationDayIntroduction = () => {
  return (
    <div className="title-text-x-block-row-upper">
      <div className="container">
        <section className="title-text-x-block-row col-lg-12 pt-2 pt-md-5 pt-xl-5">
          <div className="white-banner"></div>
          <div className="row">
            <div className="col-12 col-md-5 col-lg-5 col-xl-4 pl-md-5 slider-title">
              <h2>Hoe werkt het?</h2>
              <p>
                Heb je een te gek idee? Pitch je idee, vorm een team en maak je
                team onsterfelijk met een epic presentatie tijdens de
                innovatiemiddag. Even geen briljante ideeën? Geen probleem! Neem
                hieronder een kijkje bij de pitches. Sluit je aan bij de crew
                van de pitch die jou het meest aanspreekt.
              </p>
            </div>

            <div className="col-12 col-md-7 col-lg-7 col-xl-8 slider-blocks slick-initialized slick-slider">
              <div className="slick-list draggable" style={{ marginTop: -50 }}>
                <div
                  className="slick-track"
                  style={{
                    opacity: 1,
                    width: "741px",
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  <div
                    className="slick-slide slick-current slick-active"
                    data-slick-index="0"
                    aria-hidden="false"
                    tabIndex="0"
                    style={{ width: " 295px" }}
                  >
                    <Link
                      className="block-link link-info image-placeholder"
                      to="/pitches/add"
                      title="Commercie"
                      tabIndex="0"
                      style={{ width: "100%", overflow: "hidden" }}
                    >
                      <div className="image-placeholder">
                        <picture>
                          <img
                            height="222"
                            src="/images/PN-inno-idea.png"
                            alt="team"
                          />
                        </picture>
                        <div
                          className="category-icon-background"
                          style={{ zIndex: 100 }}
                        >
                          <img
                            className="category-icon"
                            src="/images/pn-innovation-icons_light-bulb.svg"
                            alt="category"
                          />
                        </div>
                      </div>
                      <div className="px-card">
                        <div className="inner-container">
                          <h3 className="black">Pitch je idee</h3>
                        </div>
                        <p className="black">
                          Deel je idee met de rest via het pitch formulier
                        </p>

                        <div className="svg-holder">
                          {" "}
                          <img
                            src="/images/arrow-short.svg"
                            alt="Create an pitch"
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="slick-slide slick-active"
                    data-slick-index="1"
                    aria-hidden="false"
                    tabIndex="0"
                    style={{ width: "295px" }}
                  >
                    <a
                      className="block-link link-info image-placeholder"
                      href="/#pitches"
                      onClick={(e) => {
                        e.preventDefault();
                        scroll.scrollTo(2075);
                      }}
                      title="Zorg"
                      tabIndex="0"
                      style={{ width: "100%", overflow: "hidden" }}
                    >
                      <div className="image-placeholder">
                        <picture>
                          <img
                            src="/images/PN-inno-team.png"
                            height="222"
                            alt="team"
                          />
                        </picture>
                        <div className="category-icon-background">
                          <img
                            className="category-icon"
                            src="/images/pn-innovation-icons_team-add.svg"
                            alt="zorg"
                          />
                        </div>
                      </div>
                      <div className="px-card">
                        <div className="inner-container">
                          <h3 className="black">Sluit je aan</h3>
                        </div>
                        <p className="black">Word crew member van een pitch</p>

                        <div className="svg-holder">
                          <img
                            src="/images/arrow-short.svg"
                            alt="Go to pitches"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default InnovationDayIntroduction;
