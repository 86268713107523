import React from "react";
import PitchAddForm from "../components/PitchAddForm";
import { animateScroll as scroll } from "react-scroll";

const PitchForm = (props) => {
  return (
    <div className="content" style={{ marginTop: 45 }}>
      <div
        className="text-page-fixed-row"
        // style={{ backgroundColor: "#1a1919" }}
      >
        <div className="page-subject ">
          <div className="golden-divider"></div>
          <span className="extra90degreespage bold">Pitch je idee &nbsp;</span>
          <span className="extra90degreespage ">Proud Nerds</span>
        </div>
        <div
          className="container dynamic-padding"
          style={{ paddingBottom: "290px" }}
        >
          <span
            className="striped-background  bg-white"
            style={{ opacity: 0.35 }}
          ></span>
          <div
            className="upper-content px-slide-in px-slide-delay-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
            zIndex={{ zIndex: 10000 }}
          >
            <div className="text-content" style={{ paddingTop: 50 }}>
              <div className="golden-divider"></div>
              <h1 className=" px-slide-in px-slide-delay-1">Pitch je idee!</h1>
              <h4 className=" px-slide-in px-slide-delay-2 ">
                Heb jij een vet, cool, affengeil idee voor de innovatiemiddag?
                Pitch dan NU je idee, overtuig je collega's om crew member te
                worden van je team (of koop ze om) en blaas iedereen omver met
                jullie presentatie tijdens de innovatiemiddag!
              </h4>
            </div>
            <div
              className="button-placeholder"
              style={{
                zIndex: 10000000,
                justifyContent: "center",
                display: "flex",
              }}
            >
              <a
                href="/pitches/add#"
                onClick={(e) => {
                  e.preventDefault();
                  scroll.scrollTo(875);
                }}
                to="#form"
                title="Contact"
                className="btn-size-l btn-trans row-bg-gray btn-icon-right"
              >
                Vul het formulier in
              </a>
            </div>
          </div>
          <div
            className="dynamic-placeholder pitch-form px-slide-in px-slide-delay-3 col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2"
            style={{ marginTop: "-250px", pointerEvents: "none" }}
          >
            <img
              alt="header"
              src="/images/PN-inno-idea.png"
              className="image dynamic-movable"
            />
          </div>
        </div>
      </div>

      <div className="nested-content">
        <PitchAddForm />
      </div>
    </div>
  );
};

export default PitchForm;
