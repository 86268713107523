import React from "react";

const FooterCTA = () => {
  return (
    <div className="container">
      <section className="contact-image-row row">
        <div
          className="dynamic-padding col-lg-12"
          style={{ paddingBottom: "0px" }}
        >
          <div className="content-wrapper">
            <div className="contact-content col-lg-8">
              <span className="intro-title">
                {/* <span className="golden-divider"></span> */}
                <span className="extraintro titlegold">Beter idee?</span>
              </span>
              <h1 className="main-title black">PITCH!</h1>
              <div className="rich-text black">
                <p style={{ padding: 15 }}>
                  Jouw kennende, heb jij waarschijnlijk een veel beter, vetter,
                  cooler, affengeiler idee voor de innovatiemiddag. Pitch dan NU
                  je idee bij collega's, overtuig ze om crew member te worden
                  van je team (of koop ze om) en blaas iedereen omver met jullie
                  presentatie tijdens de innovatiemiddag!
                </p>
              </div>

              <div className="button-placeholder">
                <a
                  href="/pitches/add"
                  title="Contact"
                  className="btn-size-l btn-trans row-bg-gray btn-icon-right"
                >
                  <span
                    className="icon-right-animation-line"
                    data-icon=""
                  ></span>
                  <span className="icon-right" data-icon=""></span>
                  Pitch je idee
                </a>
              </div>
            </div>
            <div className="dynamic-placeholder" style={{ marginTop: "0px" }}>
              <div className="dynamic-movable"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FooterCTA;
