import React from "react";
import { Link } from "react-router-dom";

const Succes = () => {
  return (
    <div className="content" style={{ marginTop: 45, minHeight: "85vh" }}>
      <div className="text-page-fixed-row">
        <div
          className="container dynamic-padding"
          style={{
            paddingBottom: "150px",
            marginTop: 140,
          }}
        >
          <span className="striped-background  bg-white"></span>
          <div className="upper-content px-slide-in px-slide-delay-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="text-content " style={{ paddingTop: 50 }}>
              <div className="golden-divider"></div>
              <h1 className="black px-slide-in px-slide-delay-1">
                Bedankt voor het inzenden van je pitch!
              </h1>
              <section className="module-formrow layout-floating-form row-bg-white">
                <p>
                  Bedankt voor het insturen van je pitch, hij is goed
                  aangekomen! Om je pitch en andere pitches te bekijken kan je
                  via onderstaande knop terug naar het overzicht.
                </p>
                <Link
                  to="/#pitches"
                  className="contact-form btn-size-l btn-trans row-bg-white btn-icon-right"
                >
                  <span className="icon-right" data-icon=""></span>
                  Terug naar overzicht
                </Link>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Succes;
